import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import "../../components/layout.css"
import SEO from "../../components/seo"
import ICF from "../../images/mastering-your-ICF-credential.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const MasteringICF = () => (
	<Layout>
		<SEO
			title="Mastering Your ICF Credential E-Book — AGL (719-800-2149)"
			description="Contained herein is everything you need to know to achieve certification as  a life coach. So get your digital highlighter, grab a cup of tea, and rest  assured you’re on your way to becoming the coach of your dreams, credentials included."
		/>
		<div className="page-headline">
			<div className="container">
				<div className="section-heading text-center">
					<h6 className="font-weight-bold text-uppercase flair">
						Mastering Your ICF credentials
					</h6>
				</div>
			</div>
		</div>
		<div className="page-content mastering-ICF">
			<div class="container">
				<div className="d-flow-root">
					<div className="text-left d-inline-flex">
						<Link to="/resources">RESOURCES</Link>

						<span className="mx-2">></span>

						<Link to="/resources/w23oadkqeru3ts1j7mt96e82ztt531">
							MASTERING YOUR ICF CREDENTIAL E-BOOK
						</Link>
					</div>
					<div className="d-inline-flex float-right mt-2">
						<Link to="/resources/f250aeenb0azh81jco71e0nwnhjm2q">NEXT</Link>
						<span className="mx-2">></span>
					</div>
				</div>
				<div className="row justify-content-between pt-5">
					<div className="col-md-4  pb-5 pb-md-0">
						<div className="mastering-content">
							<h2>MASTERING YOUR ICF CREDENTIAL E-BOOK</h2>
							<span>$25.00</span>
							<p className="mt-3">
								Contained herein is everything you need to know to achieve
								certification as a life coach. So get your digital highlighter,
								grab a cup of tea, and rest assured you’re on your way to
								becoming the coach of your dreams, credentials included.
							</p>
							<a href="/contact" className="team-btn justify-content-center">
								Purchase
							</a>
						</div>
					</div>
					<div className="col-md-8 text-center pb-5 pb-md-0">
						<div>
							<LazyLoadImage className="img-fluid" src={ICF} alt="ICF" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<section id="information" className="pt-0">
			<div className="container">
				<div className="col">
					<h4>CONTACT INFORMATION:</h4>
					<p>impact@coachingforgood.org</p>
					<p>719.800.2149</p>
				</div>
			</div>
		</section>
	</Layout>
)

export default MasteringICF
